define(['app'], function (app) {

  const newsletterOptIn = () => {
    const component = {};

    const _config = {
      selectors: {
        checkoutAsGuestButton: '[data-js-element=guest-checkout-trigger-modal-button]',
        email: '[data-e2e=guestUsername]',
        guestLoginNewsletterSection: '.guestLogin_newsletter_section',
        guestCheckoutForm: '[data-guest-checkout-form]',
        radioInputs: '.guestCheckout_optInRadio',
        radioStatus: 'input[name=newsletterStatus]:checked',
      },
      classes : {
        guestLoginNewsletterErrorSection: 'guestLogin_newsletter_section_error'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.checkoutAsGuestButton = component.element.querySelector(component.config.selectors.checkoutAsGuestButton);
      component.email =  component.element.querySelector(component.config.selectors.email);
      component.checkoutAsGuestButton.addEventListener('click', component.submitPreferencesAndGoToCheckout);
      component.guestCheckoutForm = component.element.querySelector(component.config.selectors.guestCheckoutForm);
      component.newsletterSection = component.element.querySelector(component.config.selectors.guestLoginNewsletterSection);
      component.radioInputs = component.element.querySelectorAll(component.config.selectors.radioInputs);

      Array.from(component.radioInputs).map(el => el.addEventListener('change', function() {
          if(component.newsletterSection.classList.contains(component.config.classes.guestLoginNewsletterErrorSection)) {
            component.newsletterSection.classList.remove(component.config.classes.guestLoginNewsletterErrorSection)
          }
      }));

      return component;
    };

    const _submitPreferencesAndGoToCheckout = (event) => {
      event.preventDefault();
      app.publish('tracking/record', 'Continue as guest', 'Clicked');
      let radioStatus = component.element.querySelector(component.config.selectors.radioStatus);
      if(radioStatus !== null)
      {
        component.submitNewsletterPreferences(radioStatus.value);
      } else {
        component.submitNewsletterPreferences("");
      }
    };

    const _errorHandler = (data) => {
      let url = new URL(window.location);
      let searchParam = new URLSearchParams(url.search);

      switch(data) {
        case '"INVALID_EMAIL"':
          searchParam.set("optInError", "email");
          break;
        case '"INVALID_OPT_IN"':
          searchParam.set("optInError", "radio");
          break;
        default:
          break;
      }
      url.search = searchParam.toString();
      window.location = url;
    };

    const _submitNewsletterPreferences = (optIn) => {
      let email = component.email.value;
      app.ajax.post({
        url: '/subscribeToNewsletter.subscribe?newsletterStatus=' + optIn,
        send: JSON.stringify(email),
        requestHeader: {
          header: 'content-type',
          value: 'application/json',
        },
        success: component.submitGuestCheckoutForm,
        error: component.errorHandler ,
      });
    };

    const _submitGuestCheckoutForm = () => {
      component.guestCheckoutForm.submit();
    };


    component.config = _config;
    component.init = _init;
    component.errorHandler = _errorHandler;
    component.submitNewsletterPreferences = _submitNewsletterPreferences;
    component.submitPreferencesAndGoToCheckout = _submitPreferencesAndGoToCheckout;
    component.submitGuestCheckoutForm = _submitGuestCheckoutForm;

    return component;
  };

  return newsletterOptIn;
});
